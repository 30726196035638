let config = {
  // Set the Demo Version
  demo: false,

  // you get this type data from google adsense

  // <ins class="adsbygoogle"
  //    style="display:block"
  //    data-ad-client="xxxxxxxxxxxx"
  //    data-ad-slot="xxxxxxxxxxxxxx"
  //    data-ad-format="auto"
  //    data-full-width-responsive="true"></ins>

  // googleAddsense
  data_ad_client: "ca-pub-9667891148162497", //ca-pub-your-ad-client-id
  data_ad_slot:"9154149295", //your-ad-slot-id

  //SEO Configurations
  metaDescription: "PsyVerse is a Web Quiz Application",
  metaKeyWords: "PsyVerse,Quiz,Questions,Answers,Online Quiz",

  //Language Configurations
  // Get Your Language Codes ---> https://developers.google.com/admin-sdk/directory/v1/languages
  supportedLanguages: ["en-GB", "hi", "ur"],
  defaultLanguage: "en-GB",

  // If your Default Language is not in supportedLanguages then add there first and after that set the defaultLanguage.

  //Quiz Configurations
  deductReviewAnswerCoins: 10, // 10 coins will be deducted if user Review the Answer
  deductIncorrectAnswerScore: 1, // This will deduct the points if user will give wrong answer
  deductLifeLineCoins: 1, // Deduct Coins when using Life Line

  // default country selection Configurations
  DefaultCountrySelectedInMobile: "in", //Default Country Selected in Mobile Login Screen

  // guess the word Quiz Configurations
  Guessthewordhintcoin: 5, //deduct coins guess the word

  // 1 vs 1 battle Quiz Configurations
  // matchrandomBattleSeconds: 30,
  battlecorrectanswer: 4,
  randomBattleSeconds:30, // 1 vs 1 battle timer seconds
  Randombattlecoins: 5, //deduct coins 1 vs 1 battle
  randomBattleoneToTwoSeconds: 2, // quick answer :- 1 vs 1 battle points 2 added on first 1 & 2 seconds
  randomBattlethreeToFourSeconds: 1, // quick answer :- 1 vs 1 battle points 1 added on first 3 & 4 seconds


  // audio question timer
  audiotimer: 30,
  mathtimer:30,

  //Firebase Configurations

  apiKey: "AIzaSyCf8Ildz7DXjXZZbN0z4lrXSlh-_VTe-YY",

  authDomain: "psyverse-e4e9b.firebaseapp.com",

  databaseURL: "https://psyverse-e4e9b-default-rtdb.firebaseio.com",

  projectId: "psyverse-e4e9b",

  storageBucket: "psyverse-e4e9b.appspot.com",

  messagingSenderId: "562553144483",

  appId: "1:562553144483:web:9ac330850dd607460f6b16",

  measurementId: "G-B26V4BKES5",


  //footer area
  companytext: "",
  addresstext: "Psynergy, Bangalore, India.",
  phonenumber: "",
  email: "contact.psyverse@gmail.com",
  facebooklink: "https://www.facebook.com/psyverse",
  instagramlink: "https://www.instagram.com/psyverse",
  linkedinlink: "https://www.linkedin.com/company/psyverse",
  weblink: "https://psyverse.in/",
  companyname: "Psyverse.in",
};

export default config;
